import {createWebHistory, createRouter} from "vue-router";



const history = createWebHistory();
const router = createRouter(
    {
        history,
        routes:[
            {

            }
        ]
    }
)

export default router