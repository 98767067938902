<script>

import SaveContent from "@/components/SaveContent.vue";
import ScrollableList from "@/components/ScrollableList.vue";

export default {
  name: "bankIndex",
  data() {
    return {
      "searchValue": "",
      listItems: [
        {
          "name": "div+CSS编程1.html",
          "path": "lib\\20240516\\div+CSS编程1.html"
        },
        {
          "name": "div+CSS编程2.html",
          "path": "lib\\20240516\\div+CSS编程2.html"
        },
        {
          "name": "Javascript事件处理编程.html",
          "path": "lib\\20240516\\Javascript事件处理编程.html"
        },
        {
          "name": "填空题1.html",
          "path": "lib\\20240516\\填空题1.html"
        },
        {
          "name": "填空题2.html",
          "path": "lib\\20240516\\填空题2.html"
        },
        {
          "name": "填空题3.html",
          "path": "lib\\20240516\\填空题3.html"
        },
        {
          "name": "填空题4.html",
          "path": "lib\\20240516\\填空题4.html"
        },
        {
          "name": "表单编程.html",
          "path": "lib\\20240516\\表单编程.html"
        },
        {
          "name": "表单编程2.html",
          "path": "lib\\20240516\\表单编程2.html"
        },
        {
          "name": "表单编程3.html",
          "path": "lib\\20240516\\表单编程3.html"
        },
          {
            "name": "vue CLI工程项目专用。 ref专题。.html",
            "path": "lib\\20240605\\vue CLI工程项目专用。 ref专题。.html"
          },
          {
            "name": "vue CLI工程项目专用。 动态传参。.html",
            "path": "lib\\20240605\\vue CLI工程项目专用。 动态传参。.html"
          },
          {
            "name": "vue CLI工程项目专用。 调用接口并传学号，从考试服务器获取token.html",
            "path": "lib\\20240605\\vue CLI工程项目专用。 调用接口并传学号，从考试服务器获取token.html"
          },
          {
            "name": "vue CLI工程项目专用。 调用接口并传学号，从考试服务器获取token2.html",
            "path": "lib\\20240605\\vue CLI工程项目专用。 调用接口并传学号，从考试服务器获取token2.html"
          },
          {
            "name": "使用Vue技术实现数据响应式处理2.html",
            "path": "lib\\20240605\\使用Vue技术实现数据响应式处理2.html"
          },
          {
            "name": "使用Vue技术实现数据响应式处理3.html",
            "path": "lib\\20240605\\使用Vue技术实现数据响应式处理3.html"
          },
          {
            "name": "使用vue技术实现数据响应式处理。.html",
            "path": "lib\\20240605\\使用vue技术实现数据响应式处理。.html"
          },
        {
          "name": "Javascript事件处理编程.html",
          "path": "lib\\20240616\\Javascript事件处理编程.html"
        },
        {
          "name": "Javascript分词操作.html",
          "path": "lib\\20240616\\Javascript分词操作.html"
        },
        {
          "name": "Javascript编程实现函数变参的处理.html",
          "path": "lib\\20240616\\Javascript编程实现函数变参的处理.html"
        },
        {
          "name": "Javascript编程实现回调.html",
          "path": "lib\\20240616\\Javascript编程实现回调.html"
        },
        {
          "name": "使用Vue技术与axios技术实现数据响应式处理与AJAX处理。.html",
          "path": "lib\\20240616\\使用Vue技术与axios技术实现数据响应式处理与AJAX处理。.html"
        },
        {
          "name": "填空题5.html",
          "path": "lib\\20240616\\填空题5.html"
        },
        {
          "name": "填空题6.html",
          "path": "lib\\20240616\\填空题6.html"
        },
        {
          "name": "定义一个myJson函数.html",
          "path": "lib\\20240616\\定义一个myJson函数.html"
        },
        {
          "name": "数组操作.html",
          "path": "lib\\20240616\\数组操作.html"
        },
        {
          "name": "AJAX编程，实现AJAX访问服务并动态更新div的内容.html",
          "path": "lib\\20240620\\AJAX编程，实现AJAX访问服务并动态更新div的内容.html"
        },
        {
          "name": "DOM编程.html",
          "path": "lib\\20240620\\DOM编程.html"
        },
        {
          "name": "HIS程序设计专项能力评测，前端程序设计专用。.html",
          "path": "lib\\20240620\\HIS程序设计专项能力评测，前端程序设计专用。.html"
        },
        {
          "name": "Javascript函数式编程.html",
          "path": "lib\\20240620\\Javascript函数式编程.html"
        },
        {
          "name": "JavaScript面向对象编程.html",
          "path": "lib\\20240620\\JavaScript面向对象编程.html"
        },
        {
          "name": "JSON编程.html",
          "path": "lib\\20240620\\JSON编程.html"
        },
        {
          "name": "使用Vue技术与axios技术实现数据响应式处理与AJAX处理。.html",
          "path": "lib\\20240620\\使用Vue技术与axios技术实现数据响应式处理与AJAX处理。.html"
        },
        {
          "name": "内置对象编程.html",
          "path": "lib\\20240620\\内置对象编程.html"
        },
        {
          "name": "填空题7.html",
          "path": "lib\\20240620\\填空题7.html"
        },
        {
          "name": "微信小程序中实现form的关键代码填空：.html",
          "path": "lib\\20240620\\微信小程序中实现form的关键代码填空：.html"
        },
        {
          "name": "微信小程序中实现form的关键代码填空：2.html",
          "path": "lib\\20240620\\微信小程序中实现form的关键代码填空：2.html"
        },
        {
          "name": "微信小程序中实现数据列表的关键代码填空.html",
          "path": "lib\\20240620\\微信小程序中实现数据列表的关键代码填空.html"
        },
        {
          "name": "微信小程序中实现数据列表的关键代码填空1.html",
          "path": "lib\\20240620\\微信小程序中实现数据列表的关键代码填空1.html"
        },
        {
          "name": "微信小程序关键代码填空：.html",
          "path": "lib\\20240620\\微信小程序关键代码填空：.html"
        },
        {
          "name": "用JavaScript自定义方法动态添加多个文本框到页面中.html",
          "path": "lib\\20240620\\用JavaScript自定义方法动态添加多个文本框到页面中.html"
        }

      ],
      listItems2: [
        {
          "name": "AJAX编程。实现AJAX访问服务并动态更新div的内容.html",
          "path": "lib\\javascript\\AJAX编程。实现AJAX访问服务并动态更新div的内容.html"
        },
        {
          "name": "avaScript面向对象编程.html",
          "path": "lib\\javascript\\avaScript面向对象编程.html"
        },
        {
          "name": "BMI健康指标计算器.html",
          "path": "lib\\javascript\\BMI健康指标计算器.html"
        },
        {
          "name": "div+CSS编程.html",
          "path": "lib\\javascript\\div+CSS编程.html"
        },
        {
          "name": "DOM编程,样式如下.html",
          "path": "lib\\javascript\\DOM编程,样式如下.html"
        },
        {
          "name": "DOM编程.html",
          "path": "lib\\javascript\\DOM编程.html"
        },
        {
          "name": "HIS程序设计专项能力评测，前端程序设计专用.html",
          "path": "lib\\javascript\\HIS程序设计专项能力评测，前端程序设计专用.html"
        },
        {
          "name": "Javascript事件处理编程.html",
          "path": "lib\\javascript\\Javascript事件处理编程.html"
        },
        {
          "name": "Javascript分词操作.html",
          "path": "lib\\javascript\\Javascript分词操作.html"
        },
        {
          "name": "Javascript基础编程 定义JSON对象.html",
          "path": "lib\\javascript\\Javascript基础编程 定义JSON对象.html"
        },
        {
          "name": "Javascript基础编程 定义json对象score.html",
          "path": "lib\\javascript\\Javascript基础编程 定义json对象score.html"
        },
        {
          "name": "Javascript基础编程 定义二维数组students.html",
          "path": "lib\\javascript\\Javascript基础编程 定义二维数组students.html"
        },
        {
          "name": "JavaScript基础编程 定义变量.html",
          "path": "lib\\javascript\\JavaScript基础编程 定义变量.html"
        },
        {
          "name": "Javascript基础编程.html",
          "path": "lib\\javascript\\Javascript基础编程.html"
        },
        {
          "name": "Javascript编程实现函数变参的处理.html",
          "path": "lib\\javascript\\Javascript编程实现函数变参的处理.html"
        },
        {
          "name": "Javascript编程实现回调.html",
          "path": "lib\\javascript\\Javascript编程实现回调.html"
        },
        {
          "name": "Javascript编程实现回调2.html",
          "path": "lib\\javascript\\Javascript编程实现回调2.html"
        },
        {
          "name": "Javascript面向对象编程.html",
          "path": "lib\\javascript\\Javascript面向对象编程.html"
        },
        {
          "name": "jquery-3.4.1.js",
          "path": "lib\\javascript\\jquery-3.4.1.js"
        },
        {
          "name": "JSON编程.html",
          "path": "lib\\javascript\\JSON编程.html"
        },
        {
          "name": "try cath错误处理.html",
          "path": "lib\\javascript\\try cath错误处理.html"
        },
        {
          "name": "XML编程.html",
          "path": "lib\\javascript\\XML编程.html"
        },
        {
          "name": "使用AJAX技术获取服务器时间.html",
          "path": "lib\\javascript\\使用AJAX技术获取服务器时间.html"
        },
        {
          "name": "使用AJAX技术获取服务器时间2.html",
          "path": "lib\\javascript\\使用AJAX技术获取服务器时间2.html"
        },
        {
          "name": "使用cookie实现离线存储.html",
          "path": "lib\\javascript\\使用cookie实现离线存储.html"
        },
        {
          "name": "前端正则编程.html",
          "path": "lib\\javascript\\前端正则编程.html"
        },
        {
          "name": "动态添加一个div到给定的父div中.html",
          "path": "lib\\javascript\\动态添加一个div到给定的父div中.html"
        },
        {
          "name": "填空题1.html",
          "path": "lib\\javascript\\填空题1.html"
        },
        {
          "name": "填空题2.html",
          "path": "lib\\javascript\\填空题2.html"
        },
        {
          "name": "填空题3.html",
          "path": "lib\\javascript\\填空题3.html"
        },
        {
          "name": "填空题4.html",
          "path": "lib\\javascript\\填空题4.html"
        },
        {
          "name": "复选框：全选，全消操作，如下图所示.html",
          "path": "lib\\javascript\\复选框：全选，全消操作，如下图所示.html"
        },
        {
          "name": "定义div，id为div_1.html",
          "path": "lib\\javascript\\定义div，id为div_1.html"
        },
        {
          "name": "定义函数appendDiv()动态生成div.html",
          "path": "lib\\javascript\\定义函数appendDiv()动态生成div.html"
        },
        {
          "name": "按如下方式完成DIV布局.html",
          "path": "lib\\javascript\\按如下方式完成DIV布局.html"
        },
        {
          "name": "按如下方式完成DIV布局2.html",
          "path": "lib\\javascript\\按如下方式完成DIV布局2.html"
        },
        {
          "name": "按如下方式完成DIV布局3.html",
          "path": "lib\\javascript\\按如下方式完成DIV布局3.html"
        },
        {
          "name": "数组操作.html",
          "path": "lib\\javascript\\数组操作.html"
        },
        {
          "name": "用JavaScript自定义方法动态添加一个文本框到页面中.html",
          "path": "lib\\javascript\\用JavaScript自定义方法动态添加一个文本框到页面中.html"
        },
        {
          "name": "用JavaScript自定义方法动态添加一个文本框到页面中2.html",
          "path": "lib\\javascript\\用JavaScript自定义方法动态添加一个文本框到页面中2.html"
        },
        {
          "name": "用JavaScript自定义方法动态添加多个文本框到页面中.html",
          "path": "lib\\javascript\\用JavaScript自定义方法动态添加多个文本框到页面中.html"
        },
        {
          "name": "编程实现取最大值操作.html",
          "path": "lib\\javascript\\编程实现取最大值操作.html"
        },
        {
          "name": "编程实现排序算法.html",
          "path": "lib\\javascript\\编程实现排序算法.html"
        },
        {
          "name": "老师说的重点.html",
          "path": "lib\\javascript\\老师说的重点.html"
        },
        {
          "name": "表单编程,样式如下.html",
          "path": "lib\\javascript\\表单编程,样式如下.html"
        },
        {
          "name": "表单编程,样式如下2.html",
          "path": "lib\\javascript\\表单编程,样式如下2.html"
        },
        {
          "name": "表单编程,样式如下3.html",
          "path": "lib\\javascript\\表单编程,样式如下3.html"
        },
        {
          "name": "请实现一个表单，包含姓名文本框与显示文本框.html",
          "path": "lib\\javascript\\请实现一个表单，包含姓名文本框与显示文本框.html"
        },
        {
          "name": "请实现一个表单，包含姓名输入框与性别选择按钮.html",
          "path": "lib\\javascript\\请实现一个表单，包含姓名输入框与性别选择按钮.html"
        },
        {
          "name": "鼠标事件编程.html",
          "path": "lib\\javascript\\鼠标事件编程.html"
        },
        {
          "name": "鼠标事件编程事件冒泡.html",
          "path": "lib\\javascript\\鼠标事件编程事件冒泡.html"
        }
      ]
    }
  },
  methods: {
    search() {

    }
  },
  components: {
    SaveContent,
    ScrollableList
  },
  mounted() {
    document.title = '代码查找系统';
  }
}


// function search() {
//   var searchText = document.getElementById("search").value.toLowerCase();
//   var buttons = document.getElementsByTagName("button");
//   if (searchText === "") {
//     for (var i = 0; i < buttons.length; i++) {
//       buttons[i].classList.remove("highlight");
//     }
//   } else {
//     for (var i = 0; i < buttons.length; i++) {
//       var buttonText = (buttons[i].textContent || buttons[i].innerText).toLowerCase();
//       if (buttonText.includes(searchText)) {
//         buttons[i].classList.add("highlight");
//       } else {
//         buttons[i].classList.remove("highlight");
//       }
//     }
//   }
// }
</script>

<template>
  <div id="home">
    <h2>
      <a class="title">Vue / 微信小程序</a><br>
      <a style="font-size: 35px">代码查找定位系统</a>
    </h2>
    <h3>使用 ctrl + F 查找</h3>
    <!--
  <div style="margin-top:20px;">
    <input type="text" id="search" placeholder="搜索文字（不区分大小写）">
    <button onclick="search()">搜索</button>
  </div>
  -->
    <div class="ScrollableList">
      <ScrollableList :items1="listItems" :items2="listItems2"/>
    </div>
    <br><br><br><br><br>
    <SaveContent/>
    <br><br><br><br><br><br>
    <div style="font-size: 40px; color: white; margin: 0 auto; text-align: center;">
      代码图谱完善中
    </div>
    <br><br><br><br><br><br><br><br>
    <el-footer>
      <footer
          style="margin-top: 50px; text-align: center; background-color: rgba(51, 51, 51, 0.2); padding: 5px; font-family: Arial, sans-serif; border-top: 1px solid #dddddd;">
        <p style="color: #fff; font-size: 14px;">预祝大家考试顺利</p>
        <p style="color: #fff; font-size: 14px;">© 2024 monjack 蜀ICP备2024080927号-1</p>
      </footer>
    </el-footer>
  </div>
</template>

<style>

@import url('https://fonts.googleapis.com/css2?family=Playwrite+US+Trad:wght@100..400&display=swap');

@font-face {
  font-family: huangkaihuaLawyerfont-2;
  src: url('/src/assets/fonts/huangkaihuaLawyerfont-2.ttf'),
  url('/src/assets/fonts/huangkaihuaLawyerfont-2.ttf'); /* IE9 */
}

.title {
  position: relative;
  top: 5px;
  font-size: 40px;
  text-align: center;
  color: #cac6c6;
  font-family: "Playwrite US Trad", "huangkaihuaLawyerfont-2", cursive;
  font-weight: lighter;
}

h2 {
  position: relative;
  text-align: center;
  font-size: 45px;
  color: #ffffff;
  font-family: 黑体;
}

h3 {
  position: relative;
  text-align: center;
  font-size: 25px;
  color: #e17373;
  font-family: "Noto Sans SC", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

button {
  height: 50%;
  background: linear-gradient(to right, #725a75, #c56c86);
  border: none;
  border-radius: 20px;
  color: #ffffff;
  padding: 10px 20px;
  opacity: 0.8;
  cursor: pointer;
}

button:hover, button:focus {
  background: linear-gradient(to right, #fd836d, #ffba69);
  opacity: 1;
  outline: none;
}

input[type="text"] {
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
}

button[type="submit"] {
  height: 30px;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  background: #6f88fc;
  color: #ffffff;
  cursor: pointer;
}

button[type="submit"]:hover {
  background: #ff5ec4;
}



#home {
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.94);
  background-size: 100% 100%;
}

.highlight {
  background: #00ff37;
  color: #000000;
}

SaveContent{
  margin: auto;
  text-align: center;

}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>