<template>
  <div class="scrollable-list">
    <ul>
      <li v-for="(item, index) in items1" :key="index">
        <button @click="jump(item.path)">{{item.name}}</button>
      </li>
    </ul>
  </div><br><br>
<div style="font-size: 20px; color:floralwhite"> 原JavaScript</div>
  <div class="scrollable-list">
  <ul>
    <li v-for="(item, index) in items2" :key="index">
      <button @click="jump(item.path)">{{item.name}}</button>
    </li>
  </ul>
  </div>

</template>

<script>
export default {
  name: 'ScrollableList',
  props: {
    items1: {
      type: Array,
      required: true
    },
    items2:{
      type: Array,
      required: true
    }
  },
  methods:{
    jump(path){
      window.location = path;
    }

  }

}
</script>

<style scoped>
.scrollable-list {
  height: 800px; /* 调整此高度来控制列表的可见部分 */
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin: auto;
  width: 800px;
}
.scrollable-list ul {
  list-style-type: none;
  padding: 0;
}
.scrollable-list li {
  margin: 10px 0;
}
.scrollable-list a {
  text-decoration: none;
  color: #007BFF;
  transition: color 0.3s;
}
.scrollable-list a:hover {
  color: #0056b3;
}
button {
  height: 40%;
  width: 500px;
  background: linear-gradient(to right, #725a75, #c56c86);
  border: none;
  border-radius: 20px;
  color: #ffffff;
  padding: 10px 20px;
  opacity: 0.8;
  cursor: pointer;
}
button:hover,button:focus {
  background: linear-gradient(to right, #fd836d, #ffba69);
  opacity: 1;
  outline: none;
}
</style>
