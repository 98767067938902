<template>
  <div>
    <h1>上传代码到此处</h1>
    <el-input
        type="textarea"
        v-model="userInput"
        rows="4"
        placeholder="输入内容。。。."
        :style="{ width: '400px' }"
    ></el-input>
    <br><br><br>
    <el-input
        v-model="fileName"
        placeholder="输入文件名（不需要后缀）"
        :style="{ width: '300px' }"
    ></el-input>
    <el-button type="primary" @click="saveContent">点击上传</el-button>

  </div>
</template>

<script>
export default {
  data() {
    return {
      userInput: '',
      fileName: ''
    };
  },
  methods: {
    async saveContent() {
      if (!this.fileName) {
        alert('Please enter a file name.');
        return;
      }

      // 创建一个FormData对象
      const formData = new FormData();
      formData.append('content', this.userInput);
      formData.append('fileName', this.fileName);

      // 发送POST请求到服务器
      const response = await fetch('http://124.223.62.222:3000/save', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert('Content saved successfully!');
      } else {
        alert('Failed to save content.');
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap');

body {
  font-family: Arial, sans-serif;
  margin: 20px;
}
h1{
  font-family: "Noto Sans SC", Helvetica, Arial, sans-serif;
}
</style>
